import React, { useState } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import Toggle from "react-toggle"
import Cookies from "universal-cookie"

import "react-accessible-accordion/dist/fancy-example.css"
import "react-toggle/style.css"
import * as CookieBannerStyles from "./cookieBanner.module.css"
import * as ShopButtonStyles from "./shop/shopButton.module.css"
import { LanguageContext } from "../utils/LanguageContext"

const CookieBanner = ({ setCookieBanner }) => {
  const cookies = new Cookies()
  const [analyticsOn, toggleAnalytics] = useState(
    !cookies.get("USER_DISABLE_ANALYTICS")
  )

  const userConsents = event => {
    event.preventDefault()
    const now = new Date()
    // * set cookie expiry in 10 years
    const expiry = now.setDate(now.getDate() + 3650)
    cookies.set("USER_COOKIE_CONSENT", 1, {
      path: "/",
      expires: new Date(expiry),
    })
    setCookieBanner(false)
  }

  const handleAnalyticsChange = toggleBool => {
    if (toggleBool) {
      // * remove cookie for disabling GA
      cookies.remove("USER_DISABLE_ANALYTICS")
    } else {
      // * set cookie for disabling GA
      const now = new Date()
      // * set cookie expiry in 6 months
      const expiry = now.setDate(now.getDate() + 180)
      cookies.set("USER_DISABLE_ANALYTICS", 1, {
        path: "/",
        expires: new Date(expiry),
      })
    }

    toggleAnalytics(toggleBool)
  }

  return (
    /* eslint-disable jsx-a11y/label-has-associated-control */
    <LanguageContext.Consumer>
      {({ language }) => (
        <div className={CookieBannerStyles.container}>
          <h2>
            Have a cookie{" "}
            <span aria-label="Cookie emoji" role="img">
              🍪
            </span>
          </h2>
          <p>
            {language === "Spanish" ? 'Usamos cookies para asegurar que te damos la mejor experiencia en nuestra web. Puedes desactivar los análisis a continuación.' : 'We use the necessary cookies to make sure you have the best experience. You can turn off analytics below.'}
          </p>
          <Accordion allowZeroExpanded>
            <AccordionItem className={CookieBannerStyles.accordionItem}>
              <AccordionItemHeading className={CookieBannerStyles.accordionHeading}>
                <AccordionItemButton>
                  {language === 'Spanish' ? 'Configuración de cookies' : 'Configure cookies'}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul className={CookieBannerStyles.cookieList}>
                  <li>
                    <label className={CookieBannerStyles.toggleButtonLabel}>
                      <Toggle
                        defaultChecked={true}
                        disabled={true}
                        className={CookieBannerStyles.toggleButton}
                      />
                      <span className={CookieBannerStyles.labelText}>
                        {language === 'Spanish' ? 'Necesario' : 'Necessary'}
                      </span>
                    </label>
                  </li>
                  <li>
                    <label className={CookieBannerStyles.toggleButtonLabel}>
                      <Toggle
                        defaultChecked={analyticsOn}
                        onChange={() => handleAnalyticsChange(!analyticsOn)}
                        className={CookieBannerStyles.toggleButton}
                      />
                      <span className={CookieBannerStyles.labelText}>
                        {language === 'Spanish' ? 'Analítica' : 'Analytics'}
                      </span>
                    </label>
                  </li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <button
            className={[
              ShopButtonStyles.shopButton,
              CookieBannerStyles.consentButton,
            ].join(" ")}
            onClick={userConsents}
          >
            OKAY
          </button>
        </div>
      )}
    </LanguageContext.Consumer>
  )
}

export default CookieBanner
