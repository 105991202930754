import React from "react"

import * as PageTitleStyles from "./pageTitle.module.css"
import { LanguageContext } from "../utils/LanguageContext"

const PageTitle = ({ title, pageTitleFromLanguage, children, description }) => {
  return (
    <h1 className={PageTitleStyles.title}>
      {pageTitleFromLanguage ? (
        <LanguageContext.Consumer>
          {({ language }) => pageTitleFromLanguage(language)}
        </LanguageContext.Consumer>
      ) : (title || children)}

      {description ? (
        <>
          {" "}
          <span className={PageTitleStyles.description}>{description}</span>
        </>
      ) : (
        ""
      )}
    </h1>
  )
}

export default PageTitle
