import { useStaticQuery, graphql } from "gatsby"

// ? Gatsby Docs: https://www.gatsbyjs.cn/docs/use-static-query/
export const useShopPages = () => {
  const { shop } = useStaticQuery(
    graphql`
      query SitePages {
        shop: allSitePage(filter: {context: {category: {name: {ne: null}}}}) {
          nodes {
            path
            context {
              category {
                name
                items {
                  uid
                }
              }
            }
          }
        }
      }
    `
  )

  const shopPages = {
    title: "Shop",
    items: shop.nodes.map(sitePage => ({
      title: sitePage.context.category.name,
      location: sitePage.path,
    }))
  }

  return shopPages
}
