import React, { Component } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"

import * as DropdownStyles from "./dropdown.module.css"

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showList: this.props.isFooterNav ? true : false,
      isMobile: null,
    }
    this.isFooterNav = this.props.isFooterNav
  }

  componentDidMount() {
    if (this.state.isMobile === null) {
      this.setState({
        isMobile: window.matchMedia("(max-width: 960px)").matches,
      })
    }
  }

  handleMouseEnter = () => {
    if (this.state.isMobile || this.isFooterNav) return
    this.setState({ showList: true })
  }
  handleMouseLeave = () => {
    if (this.state.isMobile || this.isFooterNav) return
    this.setState({ showList: false })
  }
  handleOnBlur = event => {
    if (this.isFooterNav) {
      return
    }

    this.props.onBlur(event)
  }

  render() {
    let {
      items,
      isShopRoute,
      isCollectionsRoute,
      onActiveClassName,
      linkClasses,
      titleClasses,
      title,
      tabIndex,
    } = this.props

    let itemsList = items.map(item => {
      return (
        <li key={item.location} className={DropdownStyles.listItem}>
          <AniLink
            tabIndex={tabIndex}
            className={linkClasses}
            fade
            to={item.location}
            activeClassName={onActiveClassName}
            aria-label={`Go to the ${item.title} page`}
          >
            {item.title}{" "}
            <span className={DropdownStyles.listItemDescription}>
              {item.description}
            </span>
          </AniLink>
        </li>
      )
    })

    return (
      /* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
      <div
        className={[
          DropdownStyles.dropdown,
          this.isFooterNav ? DropdownStyles.footerDropdown : "",
        ].join(" ")}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={() => this.props.toggleDropdown(this)}
        title={"Toggle dropdown list"}
        role="none"
      >
        <button
          tabIndex={this.isFooterNav ? -1 : tabIndex}
          data-type="dropdown-toggle-button"
          className={[
            titleClasses,
            this.state.showList ? DropdownStyles.isOpen : "",
            isShopRoute || isCollectionsRoute ? onActiveClassName : "",
          ].join(" ")}
          onBlur={this.handleOnBlur}
        >
          {title}
        </button>
        {this.state.showList && (
          <ul className={DropdownStyles.dropdownList}>{itemsList}</ul>
        )}
      </div>
    )
  }
}

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  isFooterNav: PropTypes.bool,
  toggleDropdown: PropTypes.func,
  onActiveClassName: PropTypes.string,
}

export default Dropdown
