import React from "react"

import * as SkipToContentButtonStyles from './skipToContent.module.css'

const SkipToContent = () => {
  const onSkipToContentClick = e => {
    e.preventDefault();
    document.querySelector('#main').focus()
  };

  return (
    <a
      className={SkipToContentButtonStyles.button}
      onClick={onSkipToContentClick}
      href='#main'
    >Skip to main content</a>
  );
};

export default SkipToContent;