import React from 'react'

import * as BackButtonStyles from "./backButton.module.css"

const BackButton = ({ className = "" }) => {
  if (typeof window === "undefined") {
    return <></>
  }

  // * check if there is history
  if (window.history.length <= 2) {
    return <></>
  }

  const goBack = () => window.history.back()

  return (
    <button
      title="Go back"
      className={[className, BackButtonStyles.backButton, "button"].join(" ")}
      onClick={goBack}
    >
      <svg
        tabIndex={-1}
        className={BackButtonStyles.backIcon}
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        width="20"
        viewBox="0 -960 960 960"
      >
        <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/>
      </svg>
    </button>
  )
}

export default BackButton