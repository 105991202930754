import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Cookies from 'universal-cookie'

import Header from "./header"
import Footer from "./footer"
import Nav from "./nav"
import PageTitle from "./../pageTitle"
import CookieBanner from "../cookieBanner"
import BackButton from "./backButton"

import LanguageProvider from "./../../utils/LanguageContext"

import * as LayoutStyles from "./layout.module.css";
import SkipToContent from "./skipToContent"

const Layout = ({
  homepage,
  backButton,
  pageTitle,
  pageTitleFromLanguage,
  pageTitleDescription,
  children,
  isShopRoute,
  isCollectionsRoute
}) => {
  const [showCookieBanner, setCookieBanner] = useState(false)

  useEffect(() => {
    const cookies = new Cookies()
    if (!cookies.get('USER_COOKIE_CONSENT')) {
      setCookieBanner(true)
    } else {
      if (typeof window !== "undefined") {
        // ? https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out#opt-out_of_measurement_for_your_site
        // ! need to set this property to true for every page if user declined use of analytics
        window['ga-disable-UA-135267871-2'] = !!cookies.get('USER_DISABLE_ANALYTICS')
      }
    }
  }, [])

  return (<>
      <StaticQuery
        query={graphql`
          query siteTitle {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <LanguageProvider>
            <Helmet>
              <meta
                name="google-site-verification"
                content="XAoSz5mYEClejB9WpVYs2pRXMCSDBFa3qd3wuyETq7w"
              />
            </Helmet>
            <SkipToContent />
            <Header siteTitle={data.site.siteMetadata.title} />
            <Nav
              isShopRoute={isShopRoute}
              isCollectionsRoute={isCollectionsRoute}
            />
            <div className={LayoutStyles.mainWrapper}>
              <main id='main' tabIndex='-1' className={homepage ? LayoutStyles.fullWidth : ""}>
                {backButton && <BackButton />}
                {(pageTitle || pageTitleFromLanguage) && (
                  <PageTitle
                    pageTitleFromLanguage={pageTitleFromLanguage}
                    title={pageTitle}
                    description={pageTitleDescription}
                  />
                )}
                {children}
              </main>
            </div>
            {showCookieBanner && <CookieBanner setCookieBanner={setCookieBanner} />}
            <Footer />
          </LanguageProvider>
        )}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
