// extracted by mini-css-extract-plugin
export var active = "nav-module--active--cqSjL";
export var basket = "nav-module--basket--a4oGw";
export var button = "nav-module--button--ybcPR";
export var collapse = "nav-module--collapse--+DfY-";
export var facebook = "nav-module--facebook--0fcaG";
export var footerNav = "nav-module--footer-nav--M88Eg";
export var instagram = "nav-module--instagram--uytzy";
export var link = "nav-module--link--gnlCC";
export var list = "nav-module--list--T+FUW";
export var menuBtn = "nav-module--menu-btn--ohvAQ";
export var nav = "nav-module--nav--0jLHt";
export var placeholder = "nav-module--placeholder--Kc6+K";
export var socialLink = "nav-module--social-link--nPPT1";
export var socialLinks = "nav-module--social-links--Z9w-A";
export var topNavButtons = "nav-module--top-nav-buttons--LMvhc";