import React, { useEffect, useState } from "react"
import { FaBars, FaTimes, FaFacebookF, FaInstagram } from "react-icons/fa"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { OutboundLink } from 'gatsby-plugin-gtag'

import * as NavStyles from "./nav.module.css"
import Dropdown from "../dropdown"
import LanguagesToggle from "./languagesToggle"

import { useShopPages } from "./navDropdownOptions"
import BasketNavButton from "./basketNavButton"

const Nav = ({ isFooterNav, isShopRoute }) => {
  const shopPages = useShopPages()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(undefined)
  const [isMobile, setIsMobile] = useState(null)

  useEffect(() => {
    if (isMobile === null) {
      setIsMobile(window.matchMedia("(max-width: 960px)").matches)
      return
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    if (!isFooterNav && isMobile) {
      // * Detect if browser supports passive event listeners
      // * Learn more: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
      var supportsPassive = false;
      try {
        /* eslint-disable getter-return */
        var opts = Object.defineProperty({}, 'passive', { get: function () { supportsPassive = true } });
        window.addEventListener("testPassive", null, opts);
        window.removeEventListener("testPassive", null, opts);
      } catch (e) { }

      const scrollEventListener = () => {
        if (window.pageYOffset > 120 && menuIsOpen) {
          setMenuIsOpen(false)
        }
      }

      document.addEventListener("scroll", scrollEventListener, supportsPassive ? { passive: true } : false)
      // * Fixes multiple event listeners being added on each Gatsby navigation
      // ? Only in Chrome Dev Tools: window.getEventListeners(document)["scroll"]
      // * returned function will run before the component unmounts (similar to `componentWillUnmount`)
      return function cleanup() {
        document.removeEventListener("scroll", scrollEventListener)
      }
    }
  }, [isMobile, menuIsOpen])

  const handleOnDropdownBlur = event => {
    const { relatedTarget } = event
    if (!relatedTarget) {
      return
    }

    // Do not close if the blur was because of expanding a dropdown or it was a link element with an href
    if (relatedTarget.getAttribute("data-type") === "dropdown-toggle-button" || (relatedTarget.tagName === "A" && !!relatedTarget.href)) {
      return
    }

    setMenuIsOpen(false)
    setOpenDropdown(null)
  }

  const menuClickHandler = () => setMenuIsOpen(!menuIsOpen)

  const handleToggleDropdown = dropdown => {
    if (isFooterNav) {
      return
    }

    if (!openDropdown) {
      dropdown.setState(prevState => ({
        showList: !prevState.showList,
      }), () => {
        setOpenDropdown(dropdown)
      })

    } else if (dropdown.props.id !== openDropdown.props.id) {
      openDropdown.setState(
        {
          showList: false,
        },
        () => {
          dropdown.setState(
            prevState => ({
              showList: !prevState.showList,
            }),
            () => dropdown.state.showList ? setOpenDropdown(dropdown) : setOpenDropdown(null)
          )
        }
      )
    } else {
      dropdown.setState({ showList: false }, () => setOpenDropdown(null))
    }
  }

  return (
    <nav
      className={[
        NavStyles.nav,
        isFooterNav ? NavStyles.footerNav : "",
      ].join(" ")}
    >
      <div className={NavStyles.topNavButtons}>
        {!isFooterNav && isMobile ? menuIsOpen ? (
          <button
            title="Close menu"
            className={NavStyles.menuBtn}
            onClick={menuClickHandler}>
            <FaTimes />
          </button>
        ) : (
            <button
              title="Open menu"
              onClick={menuClickHandler}
              className={NavStyles.menuBtn} >
              <FaBars />
            </button>
          ) : (
            ""
          )}

        <div className="flexbox-space-capture" />
        <div className={NavStyles.socialLinks}>
          <BasketNavButton
            isFooterNav={isFooterNav}
            className={NavStyles.basket}
            tabIndex={isFooterNav || !menuIsOpen ? undefined : -1}
          />
          <div className="vertical-line-separator"></div>
          <OutboundLink
            tabIndex={isFooterNav || !menuIsOpen ? undefined : -1}
            className={[NavStyles.socialLink, NavStyles.instagram].join(" ")}
            href="https://www.instagram.com/irenebielsa_/"
            aria-label={"See Be Romantic Instagram page"}
          >
            <FaInstagram />
          </OutboundLink>
          <OutboundLink
            tabIndex={isFooterNav || !menuIsOpen ? undefined : -1}
            className={[NavStyles.socialLink, NavStyles.facebook].join(" ")}
            href="https://www.facebook.com/Be-Romantic-1586483424924333/"
            aria-label={"See Be Romantic Facebook page"}
          >
            <FaFacebookF />
          </OutboundLink>
        </div>
        {/* Removed language toggle from top of the page for prettier design */}
        {isFooterNav && <LanguagesToggle />}
      </div>

      <ul
        className={[
          NavStyles.list,
          NavStyles.placeholder,
          isMobile === false || menuIsOpen || isFooterNav
            ? NavStyles.collapse
            : "",
        ].join(" ")}
      >
        <li>
          <AniLink
            tabIndex={isFooterNav || menuIsOpen ? undefined : -1}
            className={NavStyles.link}
            activeClassName={NavStyles.active}
            fade
            to="/"
            aria-label={"Homepage"}
          >
            Home
          </AniLink>
        </li>
        <li>
          <Dropdown
            tabIndex={isFooterNav || menuIsOpen ? undefined : -1}
            onBlur={handleOnDropdownBlur}
            id={"shop-dropdown"}
            toggleDropdown={handleToggleDropdown}
            title={shopPages.title}
            items={shopPages.items}
            linkClasses={NavStyles.link}
            titleClasses={NavStyles.link}
            onActiveClassName={NavStyles.active}
            isFooterNav={isFooterNav}
            isShopRoute={isShopRoute}
          />
        </li>
        <li>
          <AniLink
            tabIndex={isFooterNav || menuIsOpen ? undefined : -1}
            className={NavStyles.link}
            activeClassName={NavStyles.active}
            fade
            to="/about/"
            aria-label={"About Irene Bielsa"}
          >
            About
          </AniLink>
        </li>
        <li>
          <AniLink
            tabIndex={isFooterNav || menuIsOpen ? undefined : -1}
            className={NavStyles.link}
            activeClassName={NavStyles.active}
            fade
            to="/contact/"
            aria-label={"Contact us"}
          >
            Contact
          </AniLink>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
