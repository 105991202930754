import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Logo from "../logo"

import "./header.module.css"

const Header = () => (
  <header>
    <div>
      <AniLink
        to="/"
        fade
        top="entry"
        aria-label={'Go to the Home page'}
      >
        <Logo />
      </AniLink>
    </div>
  </header>
)

export default Header
