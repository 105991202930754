import React, { useContext, useState, useEffect } from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { BasketContext } from '../../utils/BasketContext'

import * as BasketNavButtonStyles from "./basketNavButton.module.css";
import Loader from '../loader';

const BasketNavButton = ({ isFooterNav, className = "", tabIndex }) => {
  const [ BasketPopoverBanner, setBasketPopoverBanner ] = useState(null)
  const [ showBasketPopoverBanner, setShowBasketPopoverBanner ] = useState(false)
  const [ loadingBasketPopoverBanner, setLoadingBasketPopoverBanner ] = useState(false)
  const [ isDesktop, setIsDesktop ] = useState(null)
  const { basketItems } = useContext(BasketContext)

  const path = typeof window !== 'undefined' ? window.location.pathname : '';
  const currentPageIsBasket = path === "/basket/"
  const shouldShowBasketPopover = !isFooterNav && !currentPageIsBasket

  useEffect(() => {
    if (isDesktop === null) {
      setIsDesktop(window.matchMedia("(min-width: 959px)").matches)
    }
  }, [ isDesktop ])

  const handleOnMouseEnter = async () => {
    if (!BasketPopoverBanner) {
      // * dynamically import the basket popover component
      setLoadingBasketPopoverBanner(true)
      const { default: component } = await import(/* webpackChunkName: "BasketPopoverBanner" */ "./basketPopoverBanner.js")
      const BasketPopoverBanner = React.createElement(component, {
        basketItems,
        noQuantitySelector: true
      })
      setBasketPopoverBanner(BasketPopoverBanner)
    }

    setShowBasketPopoverBanner(true)
    setLoadingBasketPopoverBanner(false)
  }

  const handleOnMouseLeave = () => setShowBasketPopoverBanner(false)

  return (
    <div
      role="button"
      tabIndex={-1}
      className={BasketNavButtonStyles.container}
      onMouseEnter={isDesktop && shouldShowBasketPopover ? handleOnMouseEnter : () => {}}
      onMouseLeave={isDesktop && shouldShowBasketPopover ? handleOnMouseLeave : () => {}}
    >
      <AniLink
        tabIndex={tabIndex}
        className={className}
        activeClassName={BasketNavButtonStyles.active}
        to="/basket/"
        fade
        aria-label={`See your shopping basket`}
      >
        <svg fill='currentColor' xmlns="http://www.w3.org/2000/svg" height="1.15em" viewBox="0 -960 960 960" width="1.15em"><path d="M240-80q-33 0-56.5-23.5T160-160v-480q0-33 23.5-56.5T240-720h80q0-66 47-113t113-47q66 0 113 47t47 113h80q33 0 56.5 23.5T800-640v480q0 33-23.5 56.5T720-80H240Zm0-80h480v-480h-80v80q0 17-11.5 28.5T600-520q-17 0-28.5-11.5T560-560v-80H400v80q0 17-11.5 28.5T360-520q-17 0-28.5-11.5T320-560v-80h-80v480Zm160-560h160q0-33-23.5-56.5T480-800q-33 0-56.5 23.5T400-720ZM240-160v-480 480Z"/></svg>

        { isDesktop === null ? <></> :
          basketItems.length > 0 && <span className={BasketNavButtonStyles.number}>{basketItems.length}</span>
        }
      </AniLink>

      <div className={BasketNavButtonStyles.basketPopoverBannerContainer}>
        { loadingBasketPopoverBanner && !showBasketPopoverBanner &&
          <Loader className={BasketNavButtonStyles.loadingPlaceholder} />
        }
        { showBasketPopoverBanner && BasketPopoverBanner }
      </div>
    </div>
  )
}

export default BasketNavButton
