import React from "react"
import { OutboundLink } from 'gatsby-plugin-gtag'

import Nav from "./nav"
import LicenceButtonImage from "./../../images/license-button.png"

import * as FooterStyles from './footer.module.css'

const Footer = () => (
  <footer className={FooterStyles.footer}>
    <Nav isFooterNav={true} />

    <OutboundLink
      className={FooterStyles.outboundLink}
      rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/" >
      <img
        loading="lazy"
        height={10}
        alt="Creative Commons Licence"
        src={LicenceButtonImage} />
    </OutboundLink>
    <p className={FooterStyles.copyrightText}>
      Except where otherwise noted, this website is licensed under a {" "}
      <OutboundLink
        rel="license"
        className={FooterStyles.outboundLink}
        href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
        Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License
      </OutboundLink>.
    </p>
    <p className={FooterStyles.copyrightText}>Irene Bielsa © {new Date().getFullYear()}, Built by
    {` `}
      <OutboundLink
        className={FooterStyles.outboundLink}
        target="_blank"
        href="https://techcenter.cy/"
      >
        Tech Center CY
      </OutboundLink>
    </p>
  </footer>
)

export default Footer
