import React from "react"

import { LanguageContext } from "./../../utils/LanguageContext"

import * as LanguagesToggleStyles from "./languagesToggle.module.css"

class LanguagesToggle extends React.Component {
  render() {
    return (
      <div className={LanguagesToggleStyles.container}>
        <LanguageContext.Consumer>
          {({ language, setLanguage }) => {
            return (
              <>
                <label
                  className={[
                    LanguagesToggleStyles.labelEnglish,
                    language === "English" ? LanguagesToggleStyles.active : "",
                  ].join(" ")}
                  htmlFor={LanguagesToggleStyles.radioEnglish}
                  aria-label="Switch to English language"
                />
                <input
                  checked={language === "English"}
                  onChange={setLanguage}
                  name="language"
                  type="radio"
                  value="English"
                  id={LanguagesToggleStyles.radioEnglish}
                />

                <label
                  className={[
                    LanguagesToggleStyles.labelSpanish,
                    language === "Spanish" ? LanguagesToggleStyles.active : "",
                  ].join(" ")}
                  htmlFor={LanguagesToggleStyles.radioSpanish}
                  aria-label="Cambiar a idioma español"
                />
                <input
                  checked={language === "Spanish"}
                  onChange={setLanguage}
                  name="language"
                  type="radio"
                  value="Spanish"
                  id={LanguagesToggleStyles.radioSpanish}
                />
              </>
            )
          }}
        </LanguageContext.Consumer>
      </div>
    )
  }
}

export default LanguagesToggle
